import { apm } from '@elastic/apm-rum';

import { clientEnv } from '@/env/client.mjs';

const shouldMonitor = clientEnv.NEXT_PUBLIC_ELASTIC_RUM_ACTIVE === 'true';

export const initMonitoring = () => {
  if (shouldMonitor) {
    return apm.init({
      serviceName: clientEnv.NEXT_PUBLIC_ELASTIC_RUM_SERVICE_NAME,
      serverUrl: clientEnv.NEXT_PUBLIC_ELASTIC_RUM_SERVER_URL,
      serviceVersion: clientEnv.NEXT_PUBLIC_ELASTIC_RUM_SERVICE_VERSION,
      environment: clientEnv.NEXT_PUBLIC_ELASTIC_RUM_ENVIRONMENT,
      flushInterval: 1000,
      ignoreTransactions: [
        /com\.snowplowanalytics\.snowplow*/,
        /collect*/,
        /j\/collect*/,
        /unip*/,
        /1559359\/log*/,
        /ads\/ga-audiences*/,
      ],
    });
  }
};
