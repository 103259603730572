'use client';

import { type PropsWithChildren, useEffect } from 'react';

import { useSession } from 'next-auth/react';

import { initMonitoring } from '@/modules/monitoring/elastic-rum/tracker';

const apm = initMonitoring();

export function RUMProvider({ children }: PropsWithChildren) {
  const { data } = useSession();

  useEffect(() => {
    const email = data?.user?.email;

    if (apm && email) {
      apm.setUserContext({ email });
    }
  }, [data?.user?.email]);

  return children;
}
