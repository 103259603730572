import {
  ConsentPlugin,
  enableGdprContext,
} from '@snowplow/browser-plugin-consent';
import {
  enableFormTracking,
  FormTrackingPlugin,
} from '@snowplow/browser-plugin-form-tracking';
import {
  enableLinkClickTracking,
  LinkClickTrackingPlugin,
} from '@snowplow/browser-plugin-link-click-tracking';
import {
  enableActivityTracking,
  newTracker,
  type SelfDescribingJson,
  trackPageView as snowplowTrackerPageView,
  trackSelfDescribingEvent,
} from '@snowplow/browser-tracker';

import { clientEnv } from '@/env/client.mjs';

export const shouldTrack = clientEnv.NEXT_PUBLIC_SNOWPLOW_ACTIVE === 'true';

export const trackPageView = () => shouldTrack && snowplowTrackerPageView();

export const enableTrackOutOfTheBoxEvents = () => {
  if (!shouldTrack) {
    return;
  }

  newTracker('sp1', clientEnv.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL || '', {
    appId: clientEnv.NEXT_PUBLIC_SNOWPLOW_ICLINIC_APP_ID || '',
    platform: 'web',
    discoverRootDomain: true,
    contexts: {
      webPage: true,
    },
    plugins: [ConsentPlugin(), FormTrackingPlugin(), LinkClickTrackingPlugin()],
  });

  enableGdprContext({
    basisForProcessing: 'consent',
    documentId: 'https://suporte.iclinic.com.br/pt-br/iclinic-termos-de-uso',
    documentVersion: '1.1.0',
    documentDescription: 'this document describes consent basis for processing',
  });

  enableFormTracking();

  enableActivityTracking({
    minimumVisitLength: 30,
    heartbeatDelay: 10,
  });

  enableLinkClickTracking();
};

export const trackCustomEvent = (
  schema: string,
  eventData: Record<string, unknown> = {},
  context: SelfDescribingJson<Record<string, unknown>>[] = [],
) => {
  if (!shouldTrack) {
    return;
  }

  try {
    trackSelfDescribingEvent({
      event: {
        schema,
        data: eventData,
      },
      context,
    });
  } catch (e) {
    console.error(e);
  }
};
