'use client';

import { type PropsWithChildren, useEffect } from 'react';

import { usePathname } from 'next/navigation';

import {
  enableTrackOutOfTheBoxEvents,
  trackPageView,
} from '@/modules/monitoring/snowplow/tracker';

if (typeof window !== 'undefined') {
  enableTrackOutOfTheBoxEvents();
}

export function SnowplowProvider({ children }: PropsWithChildren) {
  const pathname = usePathname();

  useEffect(() => {
    trackPageView();
  }, [pathname]);

  return children;
}
